import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject, catchError, map, throwError } from "rxjs";

import { environment } from "../../../environments/environment";
import { AuthService } from "../auth/auth.service";
import { AlertError } from "../error-handler";
import { HelpersService } from "../helper/helpers.service";
import { ProjectService } from "../project/project.service";

@Injectable({
	providedIn: "root",
})
export class BadgesService {
	private subjectName = new Subject<any>();

	constructor(private http: HttpClient, private authSvc: AuthService, private projectSvc: ProjectService, private helpersSvc: HelpersService) {}

	timeline(numPage: number, pageSize?: number): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const params = {
				page: numPage,
				pageSize: pageSize ? pageSize : environment.pageSize,
			};

			const url = environment.urlDomain + "/api/v1/badges/timeline?" + this.helpersSvc.serializeData(params);
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	sendUpdate(message: string) {
		this.subjectName.next({ text: message });
	}

	getUpdate(): Observable<any> {
		return this.subjectName.asObservable();
	}

	badgeRecibidos(numPage: number, personId: number): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			let params = {
				page: numPage,
				pageSize: environment.pageSize,
			};

			if (numPage == -1) {
				params = {
					page: 0,
					pageSize: 50,
				};
			}

			const url = environment.urlDomain + "/api/v1/persons/" + personId + "/badges/received?" + this.helpersSvc.serializeData(params);

			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	badgeEntregados(numPage: number, personId: number): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			let params = {
				page: numPage,
				pageSize: environment.pageSize,
			};

			if (numPage == -1) {
				params = {
					page: 0,
					pageSize: 50,
				};
			}

			const url = environment.urlDomain + "/api/v1/persons/" + personId + "/badges/delivered?" + this.helpersSvc.serializeData(params);

			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	badgeComments(badgeId: number): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/badges/" + badgeId + "/badgeComments";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	badgeLikes(badgeId: number): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/badges/" + badgeId + "/badgeLikes";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	toogleLike(badgeId: number): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const body = {
				badgeId: badgeId,
			};

			const url = environment.urlDomain + "/api/v1/badges/toggleLike/" + badgeId;
			this.http.post(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	createComment(badgeId, textComment) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const body = {
				content: textComment,
			};

			const url = environment.urlDomain + "/api/v1/badges/comment/" + badgeId;
			this.http.post(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	editComment(commentId, textComment) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const body = {
				content: textComment,
			};

			const url = environment.urlDomain + "/api/v1/badges/comment/edit/" + commentId;
			this.http.put(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	deleteComment(commentId) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/badges/comment/" + commentId;
			this.http.delete(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	badgeCommentsComment(commentId: number): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/badges/comment/" + commentId + "/comments";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	badgeLikesComment(commentId: number): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/badges/comment/likes/" + commentId;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	toogleLikeComment(commentId: number): Observable<any> {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const body = {};

			const url = environment.urlDomain + "/api/v1/badges/comment/toggleLike/" + commentId;
			this.http.post(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	respondComment(badgeId, commentId, textComment) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const body = {
				content: textComment,
			};

			const url = environment.urlDomain + "/api/v1/badges/comment/" + badgeId + "/response/" + commentId;
			this.http.post(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getPersonSuggestion() {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/badges/badgeSuggestion";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getAvailableCompetencies(personId: number) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/badges/availableCompetencies/" + personId;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getBadgeIndicators(badgeId: number) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/badges/" + badgeId + "/badgeIndicators";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					console.log("error sugerencias badge", err);
					let msgError = "No se han podido cargar las competencias";

					observer.error(msgError);
				}
			);
		});
	}

	sendBadge(grantorId, grantee, competency, indicators, motivation, badgeReturn, privateBadge) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			let params = {
				grantor: grantorId,
				competency: competency.id,
				motivation: motivation,
				badgeReturn: badgeReturn,
				origen: "web",
				privateBadge,
			};

			if (grantee.length === 1) {
				params["grantee"] = grantee[0].id;
			} else if (grantee.length > 1) {
				let grantees = [];
				for (const itemGrantee of grantee) {
					grantees.push({ id: itemGrantee.id });
				}
				params["grantees"] = grantees;
			}

			if (indicators !== null && indicators.length > 0) {
				let indicatorsIds = [];
				for (const itemIndicator of indicators) {
					indicatorsIds.push({ id: itemIndicator.id });
				}
				params["competencyIndicators"] = indicatorsIds;
			} else {
				params["competencyIndicators"] = [];
			}

			console.log("darBadge", params);

			const url = environment.urlDomain + "/api/v1/badges";
			this.http.post(url, params, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					if (err.status === 409 && err.error.message.error === "max_badge") {
						observer.error(err);
					} else {
						AlertError.showError(err);
					}
				}
			);
		});
	}

	projectCompetencies(): Observable<any> {
		return new Observable<any>((oberver) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/badges/projectCompetencies";
			this.http.get(url, options).subscribe(
				(response: any) => {
					oberver.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getProjectByPersonPeriod(periodId, personId): Observable<any> {
		const accessToken = this.authSvc.getAccessToken();

		const options = {
			headers: new HttpHeaders({
				Authentication: "Bearer " + accessToken,
				"Content-Type": "application/json; charset=utf-8",
			}),
		};

		const url = environment.urlDomain + `/api/v1/badges/getProjectByPersonPeriod/${periodId}/${personId}`;
		return this.http.get<any>(url, options).pipe(
			map((response) => response), // Si deseas transformar la respuesta
			catchError((error) => {
				// Maneja el error y lanza un error observable
				AlertError.showError(error); // Suponiendo que AlertError.showError es una función global
				return throwError(() => error);
			})
		);
	}

	badgeModerate(badgeId, estado): Observable<any> {
		return new Observable<any>((oberver) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};
			const url = environment.urlDomain + "/api/v1/badges/moderate/" + badgeId + "/" + estado;
			this.http.put(url, {}, options).subscribe(
				(response: any) => {
					oberver.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getBadge(badgeId: number) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/badges/" + badgeId;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					console.log("error sugerencias badge", err);
					let msgError = "No se han podido cargar las competencias";

					observer.error(msgError);
				}
			);
		});
	}

	getMedallasDelegacion(delegacionId: number) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/badges/badgesDelegation/" + delegacionId;

			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					console.log("error badge from delegacion", err);
					let msgError = "No se han podido cargar las competencias de la delegación";

					observer.error(msgError);
				}
			);
		});
	}
	checkSemanales() {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};
			const url = environment.urlDomain + "/api/v1/badges/checkSemanales/tandem";

			this.http.get(url, options).subscribe((response: any) => {
				observer.next(response);
			});
		});
	}
}
